html, body {
  height: 100%;
}

.main-container {
  min-height: calc(100vh - 301px);
  padding-bottom: 80px;
}

.recent-tests-container p,
.recent-tests-container hr {
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}

.recent-tests-status-count {
  display: inline-block;
  min-width: 60px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.text-success {
  color: green;
}

.text-warning {
  color: orange;
}

.text-error {
  color: red;
}